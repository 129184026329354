<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div
            style="display: flex"
          >
            <b-button
              v-b-modal.modal-1
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
            >
              <i class="fab fa-btc" />
              ถอนเครดิต
            </b-button>
            <b-button
              v-b-modal.modal-3
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mr-1"
              variant="outline-primary"
            >
              <i class="fad fa-box-full" />
              ยึดเครดิต
            </b-button>
            <b-button
              v-b-modal.modal-2
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
              <i class="fad fa-box-full" />
              ยึดเครดิตทั้งหมด
            </b-button>
          </div>
          <div>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <b-form-select
                    v-model="search_type"
                    class="d-inline-block mr-1"
                    :options="search_type_option"
                  />

                  <b-form-input
                    v-model="search_val"
                    :disabled="search_type != 1"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    block
                    @click="GetWithdraw()"
                  >
                    <feather-icon icon="SearchIcon" />
                    Search
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              :variant="
                data.item.status === 'waiting'
                  ? 'info'
                  : data.item.status === 'hold_success'
                    ? 'success'
                    : data.item.status === 'waiting_approve'
                      ? 'secondary'
                      : data.item.status === 'success'
                        ? 'success'
                        : data.item.status === 'processing'
                          ? 'warning'
                          : data.item.status === 'restore'
                            ? 'dark'
                            : 'danger'
              "
              class="badge-glow"
            >
              {{
                data.item.status === 'waiting'
                  ? 'รอทำรายการ'
                  : data.item.status === 'hold_success'
                    ? 'ยึดเครดิตสำเร็จ'
                    : data.item.status === 'waiting_approve'
                      ? 'รอยืนยัน'
                      : data.item.status === 'success'
                        ? 'สำเร็จ'
                        : data.item.status === 'processing'
                          ? 'กำลังดำเนินการ'
                          : data.item.status === 'restore'
                            ? 'คืนเงิน'
                            : 'ไม่สำเร็จ'
              }}
            </b-badge>

          </div>
        </template>
        <!-- Column: Detail -->
        <template #cell(detail)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="FileTextIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username }})"
            />
            <b-tooltip
              title="History Deposit & Withdraw"
              :target="`invoice-row-${data.item.username}-preview-icon`"
            />
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'dashboard', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->

            <b-button
              v-if="data.item.status === 'waiting_approve' && UserData.role === 'agadmin'"
              v-b-tooltip.hover.v-primary
              title="อนุมัติ"
              class="btn-icon rounded-circle"
              variant="gradient-primary"
              size="sm"
              @click="approve(data.item)"
            >
              <i
                class="fad fa-thumbs-up"
              />
            </b-button>

            <b-button
              v-if="data.item.status === 'waiting_approve' && UserData.role === 'agadmin'"
              v-b-tooltip.hover.v-warning
              title="คืนเงิน"
              class="btn-icon rounded-circle"
              variant="gradient-warning"
              size="sm"
              @click="restore(data.item)"
            >
              <i
                class="fad fa-redo"
              />
            </b-button>
          </div>
        </template>
        <template #cell(manual)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'dashboard', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->
            <b-button
              v-if="data.item.status === 'error' && data.item.status === 'processsing' && UserData.role === 'agadmin'"
              v-b-tooltip.hover.v-info
              title="ถอนมือ"
              class="btn-icon rounded-circle"
              variant="gradient-info"
              size="sm"
              @click="manual(data.item)"
            >
              <i
                class="fad fa-donate"
              />
            </b-button>
            <b-button
              v-if="data.item.status === 'error' && data.item.status === 'processsing' && UserData.role === 'agadmin'"
              v-b-tooltip.hover.v-danger
              title="รีเซ็ท"
              class="btn-icon rounded-circle"
              variant="gradient-danger"
              size="sm"
              @click="approve(data.item)"
            >
              <i
                class="fad fa-sync"
              />
            </b-button>
            <b-button
              v-if="data.item.status === 'error' && data.item.status === 'processsing' && UserData.role === 'agadmin'"
              v-b-tooltip.hover.v-success
              title="คืนเงิน"
              class="btn-icon rounded-circle"
              variant="gradient-success"
              size="sm"
              @click="restore(data.item)"
            >
              <i
                class="fad fa-redo"
              />
            </b-button>

            <b-button
              v-if="(data.item.status === 'error' || data.item.status === 'waiting' )&& UserData.role === 'agline'"
              v-b-tooltip.hover.v-info
              title="ถอนมือ"
              class="btn-icon rounded-circle"
              variant="gradient-info"
              size="sm"
              @click="manual(data.item)"
            >
              <i
                class="fad fa-donate"
              />
            </b-button>

            <b-button
              v-if="(data.item.status === 'error' || data.item.status === 'waiting' )&& UserData.role === 'agline'"
              v-b-tooltip.hover.v-success
              title="คืนเงิน"
              class="btn-icon rounded-circle"
              variant="gradient-success"
              size="sm"
              @click="restore(data.item)"
            >
              <i
                class="fad fa-redo"
              />
            </b-button>

          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetWithdraw()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetWithdraw()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="ถอนเครดิต"
        ok-title="ถอนเครดิต"
        @ok="submit"
      >
        <b-form-group>
          <v-select
            v-model="member"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="username"
            :options="optionsMember"
          />
        </b-form-group>
        <b-form-group
          label="จำนวนเงินที่ถอน"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
            <!-- <feather-icon icon="CheckSquareIcon" /> -->
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              v-model="amount"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
      <b-modal
        id="modal-2"
        title="ยึดเครดิต"
        ok-title="ยึดเครดิต"
        @ok="submitHold"
      >
        <b-form-group>
          <v-select
            v-model="member"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="username"
            :options="optionsMember"
          />
        </b-form-group>
      </b-modal>
      <b-modal
        id="modal-3"
        title="ยึดเครดิต"
        ok-title="ยึดเครดิต"
        @ok="submitHoldAmount"
      >
        <b-form-group>
          <v-select
            v-model="member"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="username"
            :options="optionsMember"
          />
        </b-form-group>
        <b-form-group
          label="จำนวนเงินที่ต้องการยึด"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <i class="fab fa-btc" />
            <!-- <feather-icon icon="CheckSquareIcon" /> -->
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              v-model="amount"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import {
  VBTooltip, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody, VBToggle, BOverlay, BIconController, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BBadge,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      search_type_option: [
        { value: 1, text: 'ยูสเซอร์เนม' },
        { value: 2, text: 'สถานะรอยืนยัน' },
        { value: 3, text: 'สถานะกำลังดำเนินการ' },
        { value: 4, text: 'สถานะคืนเงิน' },
        { value: 5, text: 'สถานะไม่สำเร็จ' },
      ],
      search_type: null,
      search_val: null,
      show: true,
      depositdata: [],
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'detail', label: 'รายละเอียดลูกค้า' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'cus', label: 'ชื่อลูกค้า' },
        { key: 'acc_no', label: 'เลขบัญชี' },
        { key: 'bank', label: 'ธนาคาร' },
        { key: 'credit_before', label: 'จำนวนเงินก่อนถอน', formatter: value => this.Commas(value.toFixed(2)) },
        { key: 'amount', label: 'เครดิตที่ถอน', formatter: value => this.Commas(value.toFixed(2)) },
        { key: 'balance', label: 'ยอดเครดิตหลังถอน', formatter: value => this.Commas(value.toFixed(2)) },
        { key: 'addby', label: 'แจ้งถอนโดย' },
        { key: 'approveby', label: 'อนุมัติโดย', formatter: value => (value || '-') },
        { key: 'manualby', label: 'ถอนมือโดย', formatter: value => (value || '-') },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'actions', label: 'อนุมัติ/คืนเงิน' },
        { key: 'manual', label: 'ถอนมือ/รีเซ็ต/คืนเงิน' },

      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      Interval: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  created() {
    this.Interval = setInterval(() => {
      this.getUserslist()
      this.GetWithdraw()
    }, 30000)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.getUserslist()
    this.GetWithdraw()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getUserslist() {
      this.$http
        .get('https://api.rmtlotto.com/api/users/list')
        .then(response => {
          this.show = false
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    GetWithdraw() {
      // let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
      }
      this.$http
        .get('https://api.rmtlotto.com/api/withdraw', { params })
        .then(response => {
          this.show = false
          this.depositdata = response.data.data
          console.log(response.data)
          // this.depositdata.forEach(items => {
          // this.getAdmin(items.approveby, index)
          // index += 1
          // })
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    // getAdmin(addby, index) {
    //   this.$http
    //     .get(`https://api.rmtlotto.com/api/admin/show/${addby}`)
    //     .then(response => {
    //       // console.log(response.data.name)
    //       this.show = false
    //       this.depositdata[index].approveby = response.data.name
    //     })
    //     .catch(error => console.log(error))
    // },
    submit() {
      this.show = true
      const formData = {
        username: this.member.username,
        uid: this.member.id,
        amount: this.amount,
        acc_no: this.member.acc_no,
        bank: this.member.bank,
      }
      this.$http
        .post('https://api.rmtlotto.com/api/withdraw/store', formData)
        .then(() => {
          this.show = false
          this.GetWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    approve(val) {
      this.show = true
      const formData = val
      this.$http
        .post('https://api.rmtlotto.com/api/withdraw/approve', formData)
        .then(() => {
          this.show = false
          this.GetWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    restore(val) {
      this.show = true
      const formData = val
      this.$http
        .post('https://api.rmtlotto.com/api/withdraw/restore', formData)
        .then(() => {
          this.show = false
          this.GetWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('คืนเงิน สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    manual(val) {
      this.show = true
      const formData = val
      this.$http
        .post('https://api.rmtlotto.com/api/withdraw/manual', formData)
        .then(() => {
          this.show = false
          this.GetWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    submitHold() {
      this.show = true
      const formData = {
        username: this.member.username,
        uid: this.member.id,
      }
      this.$http
        .post('https://api.rmtlotto.com/api/withdraw/holdstore', formData)
        .then(() => {
          this.show = false
          this.GetWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    submitHoldAmount() {
      this.show = true
      const formData = {
        username: this.member.username,
        uid: this.member.id,
        amount: this.amount,
      }
      this.$http
        .post('https://api.rmtlotto.com/api/withdraw/holdamount', formData)
        .then(() => {
          this.show = false
          this.GetWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
